import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.js";
import { Email } from '../components/Email';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Cieľom je:`}</h2>
    <ul>
      <li parentName="ul">{`Umožniť podnikateľom `}<strong parentName="li">{`združiť dostupnosť tovaru a služieb predávajúcich`}</strong>{` na tejto webovej stránke prostredníctvom `}<strong parentName="li">{`uverejnenia inzerátu ZADARMO`}</strong>{`. A to v čase reštriktívnych epidemiologických opatrení,`}</li>
      <li parentName="ul"><strong parentName="li">{`Uľahčiť vyhľadávanie aj kupujúcim`}</strong>{`, keďže združíme viacero predávajúcich na jednom mieste.`}</li>
      <li parentName="ul">{`Takýmto spôsobom `}<strong parentName="li">{`spoločne podporiť ekonomiku. Aby sme sa aj v budúcnosti mali dobre`}</strong>{`.`}</li>
    </ul>
    <h2>{`Kto sme (prevádzkovateľ):`}</h2>
    <p>{`Sme tím IT nadšencov (Tomáš Igrini, Ing. Roman Veselý, PhDr. Jarmila Lichvárová), ktorí sa rozhodli ponúknuť pomocnú ruku v čase SARS-CoV-2 pandémie.`}</p>
    <p>{`Kontakt na nás: `}<Email mdxType="Email" /></p>
    <h2>{`Ako vznikla myšlienka projektu?`}</h2>
    <p>{`Viedli k tomu dva podnety.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Ako zákazník, som si chcela objednať tovar online. Veľké reťazce mali niektorý tovar vypredaný, keďže väčšina z nás hľadala v e-shopoch práve veľkých firiem. Tak som si povedala, že nakúpim od menších firiem. Tie však nemajú až taký dobrý online marketing a horšie sa hľadajú na internete. Zároveň si vravím, veď zatvorené kamenné predajne musia mať tovar na sklade. Je možné, že vedia zabezpečiť rozvoz. Možno by som tak mohla nakúpiť aj kávu z mojej obľúbenej predajne. Možno by mi veľký nákup vedel doviezť taxikár. Ale ako všetkých nájsť a osloviť? Tráviť čas hľadaním telefonných čísiel a obvolávaním? Kto má toľko času popri varení, upratovaní, práci, učení sa s deťmi? A ako som sa neskôr dozvedela, veľa ľudí v mojom okolí by tiež takto rado pomohlo. Len kde vziať čas...`}</p>
      <p parentName="blockquote">{`Druhým podnetom boli “debaty” so známymi, ktorí majú kamenné predajne, malé e-shopy, alebo by radi robili rozvoz tovaru (napríklad taxikári), či ponúkli rôzne služby ako stavebné práce. Ich problémom však je, ako osloviť veľkú skupinu ľudí. Facebookové kontá nestačia. Peniaze na nový eshop nie sú, ani čas na marketingovú kampaň. Ich biznis stagnuje a prichádzajú o zisky. Čo v konečnom dôsledku bude mať dopad aj na nás ostatných.`}</p>
      <cite>Jarka (spoluautor projektu)</cite>
    </blockquote>
    <h2>{`Inštrukcie ako na to`}</h2>
    <h3>{`Pre zákazníkov`}</h3>
    <ol>
      <li parentName="ol">{`Zákazník má možnosť filtrovať podľa kategórie alebo lokality na celom území Slovenska.`}</li>
      <li parentName="ol">{`Zákazník kontaktuje predávajúceho cez kontaktné údaje, ktoré predávajúci uviedol v inzercii. Naša stránka nesprostredkúva priamy predaj výrobkov.`}</li>
      <li parentName="ol">{`Čím viac stránku budete zdieľať, tým viac podnikateľov a zákazníkov sa o nej dozvie. Tým viac podporíme ekonomiku. O to menšie straty budú v podnikaní a ľahší návrat do bežného života.`}</li>
      <li parentName="ol">{`A nakoniec, pokiaľ sa Vám myšlienka páči, pomohla Vám alebo máte návrhy na zlepšenie, napíšte nám na `}<Email mdxType="Email" />{`
Budeme radi, ak sa s nami podelíte o svoje dojmy. 😊`}</li>
    </ol>
    <h3>{`Pre firmy a živnostníkov`}</h3>
    <ol>
      <li parentName="ol">{`Predávajúci sa zaregistruje a následne vyplní formulár s údajmi o ponúkanom tovare alebo službe. Inzerát sa automaticky zobrazí na úvodnej stránke. Je môžné pridať viac inzerátov.`}</li>
      <li parentName="ol">{`Vytvorenie inzercie nie je spoplatnené.`}</li>
      <li parentName="ol">{`Ponúkaná služba nemusí byť len fyzický tovar. Môže sa napríklad jednať o služby poskytujúce prepravu tovaru taxikármi. Samozrejme v súlade s platnou legislatívou Slovenskej republiky a prebiehajúcimi epidemiologickými opatreniami.`}</li>
      <li parentName="ol">{`Predávajúci uverejňujúci inzerciu na tejto web stránke, musí postupovať pri predaji v súlade s platnou legislatívou Slovenskej republiky a prebiehajúcimi epidemiologickými opatreniami. Aktuálny výklad OLP/2777/2020 ÚVZ SR k spôsobu určovania možnosti otvorenia prevádzok uvádza: Uzavreté prevádzky stále môžu fungovať cez e-shop a donáškovou službou, prostredníctvom vyzdvihnutia tovaru však iba v prípade e-shopu, ak bol tovar zaplatený cez internet.`}</li>
    </ol>
    <h2>{`Odporúčané linky na aktuálne prebiehajúce opatrenia a obmedzenia pri predaji:`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.uvzsr.sk/docs/info/covid19/Opatrenie_UVZ_SR_%20final_uzatvorenie_prevadzok_rezimove_opatrenia_HH_SR_29032020.pdf"
        }}>{`http://www.uvzsr.sk/docs/info/covid19/Opatrenie_UVZ_SR_%20final_uzatvorenie_prevadzok_rezimove_opatrenia_HH_SR_29032020.pdf`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mhsr.sk/koronavirus/vyklad-uvz-sr-k-sposobu-urcovania-moznosti-otvorenia-prevadzok"
        }}>{`https://www.mhsr.sk/koronavirus/vyklad-uvz-sr-k-sposobu-urcovania-moznosti-otvorenia-prevadzok`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.uvzsr.sk/index.php?option=com_content&view=category&layout=blog&id=250"
        }}>{`http://www.uvzsr.sk/index.php?option=com_content&view=category&layout=blog&id=250`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.korona.gov.sk/covid-19-prijate-opatrenia.php"
        }}>{`https://www.korona.gov.sk/covid-19-prijate-opatrenia.php`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      